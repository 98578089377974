import { Injectable, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { UpdateOnLangChange } from '../shared/decorators/update-on-lang-change';
import { CmsApiService } from './api/cms-api.service';
import { map } from 'rxjs/operators';
import { CmsContentMapperService } from './cms-content-mapper.service';

@Injectable({
  providedIn: 'root'
})
export class StaticContentService {
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);


  /**
   * Get static content item from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('static-content/item')
  item(params: object = {}): ReplaySubject<any> {
    return this._api.staticContentItem(params).pipe(
      map(response => this._mapper.mapCmsData(response.data && response.data.item))
    ) as ReplaySubject<any>;
  }

  /**
   * Get static content list from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('static-content/list')
  list(params: object = {}): ReplaySubject<any> {
    return this._api.staticContentList(params).pipe(
      map(response => this._mapper.mapCmsData(response.data && response.data.list))
    ) as ReplaySubject<any>;
  }
}
